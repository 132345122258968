
/*
 * VNCcontact+ : A new level of contact management
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

.contact_profile_avatar_dialog {
    height: 500px;
    width: 550px;
    @media #{$xs-and-down} {
        height: 100%;
        width: 100%;
    }
    .mat-dialog-container {
        padding: 0;
        overflow: hidden;
        @media #{$sm-and-up} {
            border-radius: 8px;
            box-shadow: 0 0 14px 0 rgba(0,0,0,.4);
        }
    }
    .contact__dialog-body {
        padding-left: 0;
        position: relative;
        &.user-profile-dialog {
            .detail-contact {
               .vnc-tabs-container {
                    box-shadow: inset 0 -2px #e3e7eb;
                    ul {
                        &.vnc-tabs {
                            height: auto;
                            li {
                                &.vnc-tab {
                                    height: 48px;
                                    padding: 0 24px;
                                }
                            }
                        }
                        .tab-title{
                            font-family: 'Source Sans Pro';
                        }
                    }
                }
                vnc-tab {
                    .detail-contact-tab {
                        height: 238px;
                        overflow: auto;
                        @media(max-width: 768px) {
                            height: calc(100vh - 265px);
                         }
                    }
                }
            }
        }
    }
    .detail-contact {
        &.sticky-header {
            position: absolute;
            background: white;
            z-index: 9999;
            width: 100%;
            top: 0;
            .detail-contact-tab {
                margin-top: 60px;
            }
            .mat-tab-body-wrapper {
                min-height: 100vh;
            }
            .mat-tab-header {
                position: fixed;
                top: 48px;
                background: white;
                z-index: 999;
            }
        }
    }
    .desktop-close-button {
        .mat-icon {
            color: #888888;
            font-size: 20px;
        }
    }
    .header_lbl {
        padding: 0 0 0 15%;
        @media #{$xs-and-down} {
            padding: 0;
        }
    }
    .content {
        .user-avtar {
            text-align: center;
            line-height: 0;
            .list-avatar {
                height: 110px;
                width: 110px;
                border: 1px solid white;
                font-size: 60px;
                line-height: 110px;
                @media #{$xs-and-down} {
                    height: 94px;
                    width: 94px;
                    line-height: 94px;
                    font-size: 50px;
                    margin-top: 15px;
                }
            }
        }
        .parent-item {
            position: relative;
            height: 160px;
            border-bottom: 1px solid #e1e1e1;
            background: rgba(0, 0, 0, 0.5);
            .background-imge-avatar {
                position: absolute;
                height: 160px;
                width: 100%;
            }
            .parent-item-user-name {
                position: relative;
            }
        }
        .avatar-upload {
            display: flex;
            justify-content: center;
            padding-left: 53px;
            margin-top: -38px;
            button {
                border-radius: 50%;
                height: 37px;
                width: 37px;
                min-width: 37px;
                justify-content: center;
                display: flex;
                background: #29b6f6;
                align-items: center;
                .mat-icon {
                    color: $white-color;
                }
            }
        }
        .user-name {
            width: 100%;
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: .94;
            color: $white-color;
            margin-top: 10px;
            padding-bottom: 15px;
           // border-bottom: 1px solid #b7b7b7;
            span {
                font-family: $manrop-font-family;
                text-shadow: 0 0 6px rgba(0, 0, 0, 0.55);
            }
        }
        .contact-mail-section {
            padding: 30px 0 20px;
            .contact-detail {
                padding-top: 16px;
                padding-left: 22px;
                font-family: Roboto;
                font-size: 1rem;
                font-weight: 400;
                color: #6f6f6f;
            }
            .contact-mail {
                display: flex;
                align-items: center;
                padding-top: 15px;
                padding-left: 22px;
                .mat-icon {
                    margin: 0 10px 0 0;
                }
            }
        }
        .detail-contact {
            .mat-tab-group {
                .mat-ink-bar {
                    background: $theme-color;
                    height: 3px;
                }
                .mat-tab-label {
                    opacity: 1;
                    min-width: 100px;
                    .mat-tab-label-content {
                        font-family: $manrop-font-family;
                        color: #888888;
                    }
                    &.mat-tab-label-active {
                        .mat-tab-label-content {
                            color: $theme-color;
                            opacity: 1;
                            font-weight: bold;
                        }
                    }
                }
                .mat-tab-list {
                    border-bottom: 1px solid #f1f1f1;
                }
                .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
                    @media #{$xs-and-down} {
                        display: none;
                    }
                }
            }
            .mat-tab-body-wrapper {
                height: 245px;
                @media #{$xs-and-down} {
                    height: auto;
                }
                overflow: auto;
            }
            .mat-tab-body {
                padding-bottom: 10px;
            }
            .detail-contact-tab {
                width: 100%;
                .detail-caption-title {
                    font-weight: bold;
                    font-size: 14px;
                    margin: 15px 0 25px 10px;
                    font-family: $manrop-font-family;;
                }
                .detail-item {
                    display: flex;
                    align-items: center;
                    margin: 15px 10px;
                    .icon {
                        width: 7%;
                        @media #{$xs-and-down} {
                            width: 10%;
                        }
                        .mat-icon {
                            color: #888888;
                            line-height: 18px;
                            height: 18px;
                            width: 18px;
                            font-size: 18px;
                        }
                        &.list-chips-icon {
                            align-self: baseline;
                        }
                        &.margin-top-15 {
                            margin-top:15px;
                        }
                    }
                    .detail {
                        width: 95%;
                        @media #{$xs-and-down} {
                            width: 90%;
                        }
                        font-size: $medium-font-size;
                        font-family: $sans-pro-font-family;
                        .lable-detail {
                            font-size: 12px;
                            font-family: $sans-pro-font-family;
                            color: #888888;
                        }
                        .type {
                            color: #888888;
                        }
                        .mat-icon {
                            font-size: 5px;
                            color: #888888;
                            width: 6px;
                            margin: 0 2px 0 2px;
                            height: 6px;
                        }
                        &.list-chips {
                            .mat-chip {
                                border-radius: 3px;
                                color: $white-color;
                                font-size: 11px;
                                font-family: $sans-pro-font-family;
                                min-height: 18px;
                                .mat-icon {
                                    font-size: 10px;
                                    color: $white-color;
                                    line-height: 1.8;
                                }
                                &.tag-list-chip {
                                    background: #888888;
                                }
                                span.name {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    max-width: 200px;
                                }
                            }
                        }
                        .width-20 {
                            width: 30%;
                            @media #{$xs-and-down} {
                                width: auto;
                            }
                        }
                        .margin-top-15 {
                            margin-top:15px;
                        }
                    }
                    &.projects-item {
                        align-items: unset;
                        .icon {
                            margin: 10px 0 0 0;
                        }
                        .detail-data {
                            display: flex;
                            flex-direction: column;
                            span {
                                margin: 3px 0 0 0;
                            }
                        }
                    }
                }
                .mat-divider {
                    margin: 1% 0 0 0;
                    color: #e1e1e1;
                }
            }
        }
    }
}